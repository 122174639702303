import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const NotificationPopup = ({ close, t }) => {
  useEffect(() => {
    const timer = setTimeout(close, 7000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='notification active' id='newUserNots'>
      <div className='notification__close-icon' onClick={close} />
      <span className='title title_h3'>{t('main.notification.title', 'Заявка принята')}</span>
      <margin-4></margin-4>
      <span className='text'>
        {t('main.notification.text', 'Наш специалист свяжется с вами в ближайшее время.')}
      </span>
    </div>
  );
};

NotificationPopup.propTypes = {
  close: PropTypes.func.isRequired
};

export default NotificationPopup;
