import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationRUCommon from 'locales/ru/translation.json';
import translationRuWlCommon from 'locales/ru/translation_wl_common.json';
import translationRuWlSpecific from 'locales/ru/wl_specific.json';

import translationEnCommon from 'locales/en/translation.json';
import translationEnWlCommon from 'locales/en/translation_wl_common.json';
import translationEnWlSpecific from 'locales/en/wl_specific.json';

export const t = (key, options) => i18n.t(key, options);

const resources = {
  en: {
    translation: {
      ...translationEnCommon
    },
    translation_wl: {
      ...translationEnWlCommon
    },
    ...translationEnWlSpecific
  },
  ru: {
    translation: {
      ...translationRUCommon
    },
    translation_wl: {
      ...translationRuWlCommon
    },
    ...translationRuWlSpecific
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ru', // Выбранный язык
    debug: true,
    // returnObjects: true,

    interpolation: {
      escapeValue: false
    },
    react: {
      bindI18n: 'languageChanged',
      useSuspense: true
    },
    fallbackNS: ['translation', 'translation_wl']
  });

export default i18n;
