import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const getYear = () => {
  const date = new Date();
  const year = date.getFullYear();

  return year;
};

const Footer = ({t}) => {
  return (
    <footer className='footer'>
      <span className='footer__text'>
        © {t('main.footer.company_name', 'ООО «ЛайфТелеком»')}, {getYear()}
      </span>
      <a
        className='footer__link link'
        href='https://www.telphin.ru/static/docs/telphin_agreement_ats.pdf'
        target='_blank'
      >
        <div className='footer__icon'></div>
        <span>
          {t('main.footer.agreement_text', 'Соглашение об условиях использования Виртуальной АТС')}
        </span>
      </a>
    </footer>
  );
};


export default Footer;
