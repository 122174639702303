import React from 'react';


import Footer from './footer/footer.jsx';
import Main from './main/main.jsx';
import LngsButtons from '../templates/lngs-buttons/lngs-buttons.jsx';
import {useTranslation} from 'react-i18next';

const imgStyle = {
  ru: 'image-ru',
  en: 'image-en'
};

function TelphinLkLink({t}) {
  return <a className="header__redirect" href="https://cabinet.telphin.ru">
    <div className="header__redirect-icon"></div>
    <span className="text">{t('main.title_lk', 'Личный кабинет')}</span>
  </a>;
}

const Auth = ({wl}) => {
  const {i18n, t} = useTranslation(wl);
  const language = i18n.language;

  return (
    <div className='body'>
      <header className='header'>
        <div className={`header__logo ${imgStyle[language]}`}></div>
        {!wl && <TelphinLkLink t={t} />}
        {/* <LngsButtons /> */}
      </header>

      <Main t={t} wl={wl} language={language} />
      {!wl && <Footer t={t} />}
    </div>
  );
};

export default Auth;
