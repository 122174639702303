import React from 'react';

const errors = {
  'Неверное сочетание логина и пароля':
    'Invalid login and password combination',
  'Данный аккаунт находится в неактивном состоянии': 'This account is inactive',
  'Ошибка авторизации': 'Authorization error',
  'Неверный тип пользователя': 'Invalid user type'
};

const LoginForm = ({
  t,
  language,
  isPopupForm = false,
  close = () => null
}) => {
  const hasError = window.login_error || window.password_error;
  const passwordError = window.password_error;
  const loginError = window.login_error;
  const ifRu = language === 'ru';

  return (
    <form
      className={`form ${isPopupForm ? 'form_356 popup' : 'container__form'}`}
      method='post'
    >
      <div className='form__item form__item_top'>
        <span className='title title_h2'>
          {t('main.login_form.title', 'Вход')}
        </span>
        <margin-16 />
        <span className='title title_h3'>
          {t('main.login_form.login_title', 'Ваш логин')}
        </span>
        <margin-4 />
        <p className='text text_extra'>
          {t(
            'main.login_form.login_description',
            'Код клиента, выданный при регистрации, обычно вида XXX00000 (три латинских буквы и пять цифр).'
          )}
        </p>

        <margin-8 />
        <input
          name='login'
          type='text'
          className='input'
          placeholder={t('main.login_form.login_placeholder', 'Код клиента')}
          id='bigLoginInput'
          autoFocus={true}
          maxLength='50'
        />
        <margin-16 />
        <span className='title title_h3'>
          {t('main.login_form.password_title', 'Пароль')}
        </span>
        <margin-8 />
        <input
          name='password'
          type='password'
          className='input'
          placeholder={t('main.login_form.password_title', 'Пароль')}
          id='bigPasswordInput'
        />
        <margin-8 />
        <p className='text text_extra'>
          {t('main.login_form.forgot_password', 'Если забыли,')}
          <a
            target='_blank'
            className='link'
            href='https://apiproxy.telphin.ru/login/restore/?application_id={{ app_id }}'
          >
            &nbsp;
            {t(
              'main.login_form.reset_password_link',
              'то запросите сброс пароля'
            )}
          </a>
          .
        </p>
      </div>
      <div className={`form__error ${hasError && 'active'}`} id='bigLoginError'>
        <span className='form__error-text'>
          {ifRu ? passwordError : errors[passwordError] || passwordError}
          {ifRu ? loginError : errors[loginError] || loginError}
        </span>
      </div>
      <div className='form__item form__item_bottom' id='test'>
        <input
          className='btn '
          id='bigLoginSubmit'
          type='submit'
          value={t('main.login_form.submit_button', 'Войти в Телфин Офис')}
        />
        <a
          className='btn btn_wrapper mob-block js-close-popup'
          href='#'
          onClick={close}
        >
          <div className='btn__icon btn__icon_cancel'></div>
        </a>
      </div>
    </form>
  );
};

export default LoginForm;
