import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import useOnClickOutside from 'components/hooks/useOnClickOutside';

const getMoreParamOptions = (t) => [
  {
    value: 'virtualAts',
    label: t('main.more_param_options.virtual_ats', 'Подключение Виртуальной АТС')
  },
  {
    value: 'directNumber',
    label: t('main.more_param_options.direct_number', 'Прямой городской номер (виртуальный)')
  },
  {
    value: 'beautifulNumber',
    label: t('main.more_param_options.beautiful_number', 'Красивый номер')
  },
  {
    value: 'integration',
    label: t('main.more_param_options.integration', 'Интеграция с CRM или 1С')
  },
  // {
  //   value: 'sipPhone',
  //   label: t('main.more_param_options.sip_phone', 'Оборудование для SIP-телефонии')
  // },
  {
    value: 'corporateCellular',
    label: t('main.more_param_options.corporate_cellular', 'Мобильная связь с АТС')
  },
  {
    value: 'mobileNumber',
    label: t('main.more_param_options.mobile_number', 'Мобильный номер')
  },
  {
    value: 'number8800',
    label: t('main.more_param_options.number_8800', 'Номер 8 800')
  },
  {
    value: 'callbackWidget',
    label: t('main.more_param_options.callback_widget', 'Виджет обратного звонка')
  }
];

const getErrors = (t) => ({
  empty: t('main.errors.empty', 'Укажите контактные данные, чтобы мы смогли с вами связаться.'),
  invalidPhone: t('main.errors.invalid_phone', 'Некорректный номер телефона')
});

const OrderConsPopup = ({ close, openNotifi, t }) => {
  const [name, setName] = useState();
  const [phone, setPhone] = useState('+7');
  const [email, setEmail] = useState();
  const [comment, setComment] = useState();
  const [moreParam, setMoreParam] = useState([]);
  const [error, setError] = useState('');

  const moreParamOptions = getMoreParamOptions(t);
  const errors = getErrors(t);

  const [showAddText, setShowAddText] = useState(false);

  const mask = event => {
    const inputValue = event.target.value.replace(/\D/g, '');
    let maskedValue = '+7';

    if (inputValue.length >= 1) {
      maskedValue += ' (' + inputValue.substring(1, 4);
    }
    if (inputValue.length >= 4) {
      maskedValue += ') ' + inputValue.substring(4, 7);
    }

    if (inputValue.length >= 7) {
      maskedValue += '-' + inputValue.substring(7, 9);
    }
    if (inputValue.length >= 9) {
      maskedValue += '-' + inputValue.substring(9, 11);
    }

    setPhone(maskedValue);
  };

  const handleSubmit = async () => {
    // let url = 'test.ru';
    let url = 'https://www.telphin.ru/ajaxForm/orderForm';

    if (!name || !phone || !email) {
      setError(errors['empty']);
      return;
    }

    if (phone.length !== 18) {
      setError(errors['invalidPhone']);
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('email', email);
    comment && formData.append('comment', comment);

    moreParam.forEach(elem => {
      formData.append('extra[]', elem);
    });

    let options = {
      method: 'POST',
      body: formData
    };

    try {
      await fetch(url, options);
      openNotifi(true);
      close();
    } catch (e) {
      console.log('error');
    }
  };

  const handleCheckbox = value => {
    const findVal = moreParam.find(elem => elem === value);

    if (findVal) {
      setMoreParam(moreParam.filter(i => i !== value));
    } else {
      setMoreParam([...moreParam, value]);
    }
  };

  const ref = useRef();
  useOnClickOutside(ref, close);

  return (
    <div className='popup__bg active'>
      <div className='popup__wrapper'>
        <div className='popup__close-icon' onClick={close} />
        <form className='form form_356 popup' ref={ref}>
          <div className='form__error'>
            <span className='form__error-text'></span>
          </div>
          <div className='form__item form__item_top'>
            <span className='title title_h2'>
              {t('main.order_cons_popup.title', 'Заказ консультации')}
            </span>
            <margin-16 />
            <span className='title title_h3'>
              {t('main.order_cons_popup.name_label', 'Ваше имя')}
            </span>
            <margin-8 />
            <input
              className={`input ${errors['empty'] === error && 'invalid'}`}
              type='text'
              name='name'
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <margin-16 />
            <span className='title title_h3'>
              {t('main.order_cons_popup.phone_label', 'Номер телефона')}
            </span>
            <margin-8 />

            <input
              className={`input ${error && 'invalid'}`}
              type='tel'
              placeholder='+7 (___) ___-__-__'
              onChange={mask}
              value={phone}
            />

            <margin-16 />
            <span className='title title_h3'>
              {t('main.order_cons_popup.email_label', 'Электронная почта')}
            </span>
            <margin-8 />
            <input
              className={`input ${errors['empty'] === error && 'invalid'}`}
              type='email'
              name='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <margin-16 />
            {!showAddText && (
              <a className='btn btn_text' href='#' onClick={() => setShowAddText(true)}>
                {t('main.order_cons_popup.additional_wishes', 'Дополнительные пожелания')}
              </a>
            )}
            {showAddText && (
              <div className='popup__checklist active'>
                <div className='popup__checklist-wrapper'>
                  <span className='title title_h3'>
                    {t('main.order_cons_popup.additional_wishes', 'Дополнительные пожелания')}
                  </span>
                  <margin-8 />

                  {moreParamOptions.map(({ value, label }) => (
                    <>
                      <div className='checkbox__wrapper' onClick={() => handleCheckbox(value)}>
                        <input
                          className='checkbox'
                          type='checkbox'
                          checked={moreParam.find(elem => elem === value)}
                        />
                        <label className='checkbox'></label>
                        <label className='checkbox__label'>{label}</label>
                      </div>
                      <margin-8 />
                    </>
                  ))}

                  <margin-16 />
                  <span className='title title_h3'>
                    {t('main.order_cons_popup.comment', 'Комментарий')}
                  </span>
                  <margin-8 />
                  <textarea
                    className='input input_area'
                    name='comment'
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                </div>
              </div>
            )}

            <margin-16 className='mob-block'></margin-16>
            <p className='text text_extra mob-block'>
              {t('main.order_cons_popup.privacy_policy', 'Нажимая на кнопку, вы соглашаетесь ')}
              <a className='link' href='#' target='_blank'>
                {t(
                  'main.order_cons_popup.privacy_policy_link',
                  'с правилами обработки персональных данных'
                )}
              </a>
              .
            </p>
          </div>
          <div className={`form__error ${error && 'active'}`}>
            <span className='form__error-text'>{error}</span>
          </div>
          <div className='form__item form__item_bottom'>
            <input
              className='btn'
              type='button'
              value={t('main.order_cons_popup.submit_button', 'Отправить заявку')}
              onClick={handleSubmit}
            />
            <margin-8 className='mob-none'></margin-8>
            <p className='text text_extra mob-none'>
              {t('main.order_cons_popup.privacy_policy', 'Нажимая на кнопку, вы соглашаетесь ')}
              <a className='link' href='#' target='_blank'>
                {t(
                  'main.order_cons_popup.privacy_policy_link',
                  'с правилами обработки персональных данных'
                )}
              </a>
              .
            </p>
            <a className='btn btn_wrapper mob-block js-close-popup' href='#' onClick={close}>
              <div className='btn__icon btn__icon_cancel'></div>
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

OrderConsPopup.propTypes = {
  close: PropTypes.func.isRequired,
  openNotifi: PropTypes.func.isRequired
};

export default OrderConsPopup;
